h1, h2, h3, h4, h5, h6 {
  font-weight: bolder;
  font-style: normal;
  text-rendering: optimizeLegibility;
  line-height: 1.1;
  //margin-bottom: 12px;
  //margin-top: 12px;
}

h1 {
  font-size: $h1-fontsize;
  color: $h1-color;
  margin: $h1-margin;
  font-family: $font-family-headers;
}

h2 {
  font-size: $h2-fontsize;
  color: $h2-color;
  margin: $h1-margin;
  font-family: $font-family-headers;
}

h3 {
  font-size: $h3-fontsize;
  color: $h3-color;
  margin: $h1-margin;
  font-family: $font-family-headers;
}

h4 {
  font-size: $h4-fontsize;
  color: $h4-color;
  margin: $h1-margin;
  font-family: $font-family-headers;
}

h5 {
  font-size: $h5-fontsize;
  color: $h5-color;
  margin: $h1-margin;
  font-family: $font-family-headers;
}

h6 {
  font-size: $h6-fontsize;
  color: $h6-color;
  margin: $h1-margin;
  font-family: $font-family-headers;
}

p {
  font-size: $paragraph-fontsize;
  color: $paragraph-color;
  line-height: $paragraph-lineheight;
}

a {
  color: $link-color;
}

a:hover {
  color: $hover-color !important;
}

ul, ol {
  padding-left: 15px;
}
