@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 992px) {

  header {
    &.main-header {
      min-height: 120px;

      .toparea {
        height: 120px;

        .bg-white {
          padding: 5px 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
        }

        .bg-grey {
          padding: 5px 0;
          display: flex;
          flex-direction: row;
          justify-content: center;

          .navigation {
            justify-content: center;
          }
        }
      }
    }
  }

  #main-links-container {
    display: none;
  }

  main {
    padding: 0;

    .resumearea {
      background: $blue;
      padding: 15px;

      .resumetext {
        padding-right: 15px;
      }

      .resumeimg {
        padding-left: 15px;
      }
    }

    .wpcf7-form input[type=submit] {
      width: 100%;
    }

    /** PageBuilder CSS **/

    .panel-layout {

      .panel-grid {
        flex-direction: column;
        justify-content: flex-start;

        .panel-grid-cell {

        }
      }

    }

  }

  footer {
    .right-footer {
      .fab {
        margin: 10px 10px 10px 0;
      }
    }
  }

  #slidersection .sliderarea .theslider {
    width: 100%;
  }

  .dotsarea .bg-grey {
    display: none;
  }

  .right-footer {
    text-align: left !important;
  }

  .mm-menu_position-bottom {
    height: 70vh;
  }

}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 600px) {

}

@media screen and (max-width: 450px) {

}
