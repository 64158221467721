header.main-header {
  background: transparent;
  min-height: 100px;
  top: 0;
  width: 100%;
  z-index: 100;

  transition: all .5s linear;

  &.sticky {
    position: fixed !important;
    background: $nav-background;
    top: 0;
    width: 100%
  }

  .container-fluid {
  }

  .bg-white {
    background-color: $white;
  }

  .bg-grey {
    background-color: $grey;
  }

  .border-top-blue {
    border-top: 15px solid $blue;
  }

  .toparea {
    height: 100px;

    .bg-white {
      padding-left: 12%;
    }

    .bg-grey {
      padding-right: 12%;

      .navigation {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-end;

        .hamburger {
          padding: 0;
        }
      }
    }

    .logolink {

      position: relative;

      img {
        height: 70px;
      }

    }

  }

}

#main-links-container {
  display: flex;
  width: calc(100% + 30px);
  height: 33px;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  top: -40px;
  left: -15px;

  .main-link-a {
    display: block;
    width: 100%;
    text-align: center;
    background-color: $blue;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-8px);
    }

    a {

      display: block;
      width: 100%;
      height: 100%;

      &:hover {
        color: $fontblack !important;
        text-decoration: none;
      }

    }
  }

}

.bg-white {
  background-color: $white;
}

.bg-grey {
  background-color: $grey;
}

.bg-white, .bg-grey {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.border-top-blue {
  border-top: 15px solid $blue;
  justify-content: flex-start;
}

#slidersection {

  .sliderarea {
    max-height: 690px;
    background-color: $grey;

    .theslider {
      max-height: 690px;
      width: 88%;
      margin-bottom: 0;

      .slick-slide {
        height: auto;
        max-height: 690px;

        img {
          object-fit: cover;
          width: 100% !important;
          height: 100% !important;
        }

      }

      .slick-track {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        overflow: hidden;
      }

      .slick-dots {
        z-index: 1;
        bottom: -85px;

        li {
          width: 25px;
          height: 25px;

          button {
            width: 25px;
            height: 25px;

            &:before {
              opacity: 1;
              color: $blue;
              font-size: 15px;
            }
          }

          &.slick-active button:before {
            opacity: 1;
            color: $fontblack;
          }

        }

      }

    }
  }

  .dotsarea {
    height: 130px;
  }
}

#wpadminbar {
  min-height: unset !important;
}

@import 'navigation';
