ul#menu-hauptmenu {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
  padding-left: 0;

  li {
    &.menu-item {

      border-bottom: 1px solid rgba(0, 0, 0, 0.5);

      &:nth-last-child() {
        border-bottom: none;
      }

      a {
        display: block;
        width: 100%;
        min-height: 50px;
        height: auto;
        font-size: 1.7em;
        line-height: 1.1em;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }

    &.current-menu-item a{
      color: $blue;
    }
  }

}