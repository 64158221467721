/* * * * * * * * * * * * */
/* * * * VARIABLES * * * */
/* * * * * * * * * * * * */

/* ---------------------- */
/* ---  BREAK POINTS  --- */
/* ---------------------- */

/* ---------------- */
/* ---  COLORS  --- */
/* ---------------- */

// Default paragraph-color
$black: #000;
$white: #fff;
$fontblack: #1d1d1b;
$grey: #e8e8e8;
$blue: #a9eef2;


// note: feel free to modify each color separately below or add new variables

/* -------------- */
/* ---  FONT  --- */
/* -------------- */
@import url('https://fonts.googleapis.com/css?family=Noto+Sans|Playfair+Display');

$font-family-headers: 'Nanum Myeongjo', serif;
$font-family-paragraph: 'Segoe UI', sans-serif;

$font-color: $fontblack;
$link-color: $black;
$hover-color: $black;

$h1-fontsize: 2em;
$h1-color: $fontblack;
$h1-margin: .67em 0;

$h2-fontsize: 1.5em;
$h2-color: $fontblack;
$h2-margin: .75em 0;

$h3-fontsize: 1.17em;
$h3-color: $fontblack;
$h3-margin: .83em 0;

$h4-fontsize: 1.12em;
$h4-color: $fontblack;
$h4-margin: .90em 0;

$h5-fontsize: .83em;
$h5-color: $fontblack;
$h5-margin: 1.5em 0;

$h6-fontsize: .67em;
$h6-color: $fontblack;
$h6-margin: 1.67em 9;

$paragraph-fontsize: 1em;
$paragraph-color: $fontblack;
$paragraph-lineheight: 22px;


/* ----------------- */
/* ---- LAYOUTS ---- */
/* ----------------- */
// include _foundaition.scss

$bodybackground: $white;


/* ---------------------- */
/* ----  NAVIGATION  ---- */
/* ---------------------- */

// background color
$nav-background: $blue;

// background hovers
$menu-background-hover: #92d8dc;

// link color for main menu -> root layer
$menu-link-color: $fontblack;
$menu-link-color-hover: $fontblack;
$menu-link-letterspacing: 1px;

// font
$menu-link-fontsize: 15px;
$submenu-link-fontsize: 13px;

// width / height
$nav-height: 70px;
$logo-width: 200px;
$logo-background: transparent;
$rootitem-width: 80px;
$subitem-width: 100px;

$logo-padding: 0;
$submenu-padding: 10px 0;


// MOBILE MENU
$hamburger-color: $fontblack;


/* ---------------------- */
/* ------  FOOTER  ------ */
/* ---------------------- */
// include _footer.scss

// dimensions
$footer-height: 200px;
$footer-background-color: $grey;
$footer-font-color: $fontblack;