html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

@import 'normalize';
@import 'fonts';
@import 'variables';
@import 'typography';


html, body {
  height: 100%;
  font-family: $font-family-paragraph;
}

body {
  background: $bodybackground;
}

// STICKY FOOTER
$header-footer-height: calc(#{$nav-height} + #{$footer-height});
.content-section {
  min-height: calc(100vh - #{$footer-height});
}

button:focus {outline:0;}