main {
  padding: 50px 0;


  .resumearea {

    background: linear-gradient(90deg, $blue 75%, rgba(0, 0, 0, 0.0) 25%);
    padding: 50px 12%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .resumetext {
      padding-right: 30px;
    }

    .resumeimg {
      padding-left: 30px;
      padding-right: 0;

      img {
        width: 100%;
        height: auto;
      }
    }

  }

  .teaserarea {
    padding: 50px 12%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .col-content {

      img {
        width: 100%;
      }

    }

    .col-teasers {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .teaserlink {

        margin: 25px 0;
        padding: 10px;

        &:hover {
          background-color: $blue;
          text-decoration: none;

          .icon {
            color: $white;
          }

          .teasertext {

            ul {
              color: $fontblack;

              li {
                color: $fontblack;
              }
            }

          }
        }

        .icon {
          font-size: 40px;
          color: $blue;
        }

        h3.teasertitle {
          line-height: 2em;
        }

        .teasertext {

          ul {
            padding-left: 40px;
          }

        }

      }

    }

  }

  .additional_content {
    padding: 45px 0;

    .wpcf7-form label {
      width: 100%;
    }

    .wpcf7-form input[type=text], .wpcf7-form input[type=email], .wpcf7-form textarea {
      width: 100%;
      line-height: 1.5;
    }

    .wpcf7-form input[type=submit] {
      background-color: $blue;
      border: none;
      padding: 5px 10px;
    }

  }

}

/** PageBuilder CSS **/

.panel-layout {

  .panel-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .panel-grid-cell {
      flex-grow: 1;
      flex-basis: 0;
      padding: 15px;

      .gallery-item {
        text-align: left !important;
        margin-top: 5px !important;

        img.size-medium, img.size-large {
          width: 100%;
          height: auto;
        }
      }
    }
  }

}
