footer {
  min-height: $footer-height;
  background: $footer-background-color;
  display: flex;
  align-items: center;
  border-top: 15px solid $blue;

  div, div a, ul li, ul li a {
    color: $fontblack;
  }

  a {
    text-decoration: none;
  }

  .col-footer {
    padding: 0 12%;
  }

  .right-footer {
    text-align: right;

    .fab {
      font-size: 22px;
      margin: 10px 0 10px 10px;
    }
  }
}
