@import url('https://fonts.googleapis.com/css?family=Nanum+Myeongjo');

@font-face {
  font-family: 'Segoe UI';
  src: url('../fonts/segoeui.woff')  format('woff');
  font-weight: normal;
  font-style: normal;
}

/*@font-face {
  font-family: 'Segoe UI Semibold';
  src: url('../fonts/seguisb.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../Fonts/Open_Sans/OpenSans-Bold.ttf')  format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../Fonts/Open_Sans/OpenSans-BoldItalic.ttf')  format('truetype');
  font-weight: bold;
  font-style: italic;
}
*/